body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.editIcon {
  color: #20a8d8;
  margin-right: 20px;
}
.deleteIcon {
  color: #f86c6b;
}

img {
  vertical-align: middle;
  border-style: none;
  object-fit: contain;
}

.carFormImage {
  height: 200px !important;
  width: 200px !important;
  border: 1px solid #e4e7ea !important;
  border-radius: 0.25rem !important;
}
.autocompleteinput {
  width: 100%;
  height: 35px;
  border-radius: 5px;
  border-color: rgb(228, 231, 234);
  border-style: solid;
  border-width: 1px;
  padding: 0px 10px;
  color: rgb(92, 104, 115);
  font-size: 0.875rem;
  font-weight: 400;
  outline: 0;
}

.autocompleteinput:hover:focus {
  width: 100%;
  height: 35px;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  padding: 0px 10px;
  color: rgb(92, 104, 115);
  font-size: 0.875rem;
  font-weight: 400;
  border-color: #8ad4ee;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
}

/* .rt-td {
  display: flex;
  align-items: center;
  justify-content: center;
} */
.err .ql-toolbar.ql-snow {
  border: 1px solid red !important;
}
.err .ql-container.ql-snow {
  border-top: 0px solid red !important;
  border-bottom: 1px solid red !important;
  border-left: 1px solid red !important;
  border-right: 1px solid red !important;
}
.noErr .ql-toolbar.ql-snow {
  border: 1px solid #e4e7ea !important;
}
.noErr.ql-container.ql-snow {
  border-top: 0px solid #e4e7ea !important;
  border-bottom: 1px solid #e4e7ea !important;
  border-left: 1px solid #e4e7ea !important;
  border-right: 1px solid #e4e7ea !important;
}
.ql-editor{
  color: #5c6873;
}